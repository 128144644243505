<template>
  <page v-loading="loading">
    <el-form inline :model="params" ref="filtersForm">
      <el-form-item label="出库类型" prop="invoiceType">
        <el-select
          v-model="params.invoiceMethod"
          placeholder="请选择出库类型"
          clearable
        >
          <el-option value="100" label="退货出库"></el-option>
          <el-option value="101" label="其他出库"></el-option>
        </el-select>
        <!-- <el-input v-model="params.invoiceMethod" /> -->
      </el-form-item>
      <el-form-item label="出库仓库" prop="groupId">
        <el-select
          v-model="params.groupId"
          placeholder="请选择出库仓库"
          clearable
        >
          <el-option
            v-for="(itme, index) in groupList"
            :key="index"
            :value="itme.value"
            :label="itme.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出货单号" prop="invoiceOrder">
        <el-input
          v-model="params.invoiceOrder"
          placeholder="请填写出货单号(选填)"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="params.remark" placeholder="请备注(选填)" />
      </el-form-item>
    </el-form>
    <div>
      <span>出库商品清单：{{ tableData.length }} 种商品</span>
      <span style="margin-left: 30px">合计（元）：{{ sumNnbers() }}</span>
      <el-button type="primary" style="margin-left: 30px" @click="openList"
        >+ 增加</el-button
      >
      <el-button type="primary" @click="onMultipleDel">- 删除</el-button>
    </div>
    <div class="table-wrap">
      <el-table
        :data="tableData"
        border
        height="100%"
        style="width: 100%"
        v-loading="loading"
        @selection-change="SelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="条形码"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="specification"
          label="规格"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="brandName"
          label="品牌"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsCount"
          label="出库数量"
        >
          <template slot-scope="{ row }">
            <el-input-number
              controls-position="right"
              v-model="row.goodsCount"
              :min="1"
              :max="100000000000"
            ></el-input-number>
            <!-- <el-input @change="countInput(row.goodsCount)" v-model="row.goodsCount" /> -->
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="price"
          label="进货平均单价（元）"
        >
          <!-- <template slot-scope="{ row }">
            <el-input v-model="row.price" />
          </template> -->
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="total"
          label="出库小计（元）"
        >
          <template slot-scope="{ row }">
            {{ sumTotal(row) }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="100px"
          prop="sellPrice"
          label="当前零售价（元）"
        ></el-table-column>

        <el-table-column
          show-overflow-tooltip
          prop="stock"
          label="商品当前库存"
        >
          <!-- <template slot-scope="{ row }">
            <el-input v-model="row.stock" />
          </template> -->
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 10px">
      <el-button @click="cbrank">返回</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
    <!-- <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    /> -->
    <div>
      <el-dialog
        :destroy-on-close="true"
        :close-on-click-modal="false"
        title="商品详情"
        :visible.sync="addShow"
        width="900px"
      >
        <el-form inline>
          <el-form-item label="商品条码：">
            <el-input v-model="dolData.barNo" />
          </el-form-item>
          <el-form-item label="商品名称：">
            <el-input v-model="dolData.goodsName" />
          </el-form-item>
          <el-button @click="getProductList()">查询</el-button>
        </el-form>
        <el-table
          :data="addTableData"
          border
          ref="multipleTable"
          min-height="400px"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            label="商品名称"
            show-overflow-tooltip
            prop="goodsName"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="barNo"
            label="条形码"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="specification"
            label="规格"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="brandName"
            label="品牌"
          ></el-table-column>
        </el-table>
        <pagination
          :totalCount="totalCount"
          :page-size.sync="dolData.limit"
          :page.sync="dolData.page"
          @change="getProductList"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="addShow = false">取消</el-button>
          <el-button type="primary" :loading="loading" @click="addProductList"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  mixins: [watchParamsGetData],

  data() {
    return {
      loading: false,
      currentItem: null,
      addShow: false,
      addVisible: false,
      groupList: [],
      tableData: [],
      addTableData: [],
      addList: [],
      dolData: {
        limit: 20,
        page: 1
      },
      params: {
        invoiceType: 2,
        invoiceDetails: []
      },
      totalCount: 0,
      multipleSelection: [],
      multipleSelectAry: [],
      testSumList: []
    }
  },

  created() {
    this.getRkuData()
  },
  methods: {
    async getRkuData() {
      try {
        const res = await this.$api.groupList.getGroupAllList()
        this.groupList = (res.group || []).map(item => {
          return {
            label: item.groupName,
            value: item.id
          }
        })
      } catch (err) {}
    },
    openList() {
      this.getProductList()
      this.addShow = true
    },
    sumTotal(row) {
      return Number(row.goodsCount) * Number(row.price) || 0.0
    },
    sumNnbers() {
      let sums = 0
      for (let i = 0; i < this.testSumList.length; i++) {
        if (this.testSumList[i].sumData) {
          sums = sums + this.testSumList[i].sumData
        }
      }
      return sums
    },
    async getProductList() {
      try {
        const res = await this.$api.commodity.getProductList(this.dolData)
        if (res.page && res.page.list) {
          this.addTableData = res.page.list.map(item => {
            return item
          })
          this.totalCount = res.page.totalCount
        }
      } catch (error) {}
    },
    handleSelectionChange(val) {
      this.addList = []
      this.addList = val.map(item => {
        return {
          price: item.inPrice,
          goodsId: item.id,
          sellPrice: item.sellPrice,
          goodsName: item.goodsName,
          barNo: item.barNo,
          stock: item.stock,
          specification: item.specification,
          brandName: item.brandName
        }
      })
    },
    addProductList() {
      this.tableData.push(...this.addList)
      this.addList = []
      this.$refs.multipleTable.clearSelection()
      this.addShow = false
    },
    cbrank() {
      this.$router.push({
        name: 'outboundGoods'
      })
    },
    async submit() {
      try {
        this.loading = true
        let params = { ...this.params }
        params.invoiceDetails = this.tableData
        const res = await this.$api.commodity.addVehicleList(params)
        this.cbrank()
        this.loading = false
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    SelectionChange(rows) {
      this.multipleSelection = rows
      rows.forEach(item => {
        this.multipleSelectAry.push(item.barNo)
      })
    },
    onMultipleDel() {
      if (this.multipleSelectAry.length == 0) {
        this.$message.error('请选择要删除的数据')
      }
      this.tableData = this.tableData.filter((item, index) => {
        return !this.multipleSelectAry.includes(item.barNo)
      })
    }
  },
  watch: {
    tableData: {
      handler(val) {
        let newData = val.map((item, index) => {
          item.sumData = Number(item.goodsCount) * Number(item.price)

          return item
        })
        this.testSumList = newData
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped lang="scss"></style>
